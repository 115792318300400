<template>
  <v-col cols="12">
    <v-row>
    <v-col cols="6">
      <country-select v-model="address.country"></country-select>
    </v-col>

    <v-col cols="6">
      <v-text-field
        :label="$vuetify.lang.t('$vuetify.paymentForm.fields.address')"
        autocomplete="address-line1"
        v-model="address.line1"
      ></v-text-field>
    </v-col>

    <v-col cols="6">
      <v-text-field
        :label="$vuetify.lang.t('$vuetify.paymentForm.fields.city')"
        autocomplete="address-level2"
        v-model="address.city"
      ></v-text-field>
    </v-col>

    <v-col cols="6">
      <v-text-field
        :label="$vuetify.lang.t('$vuetify.paymentForm.fields.state')"
        autocomplete="state"
        v-model="address.state"
      ></v-text-field>
    </v-col>

    <v-col cols="6">
      <v-text-field
        :label="$vuetify.lang.t('$vuetify.paymentForm.fields.postal_code')"
        autocomplete="postal-code"
        v-model="address.postal_code"
      ></v-text-field>
    </v-col>
    </v-row>
  </v-col>
</template>

<script>
const CountrySelect = () => import('./CountrySelect.vue');

export default {
  name: 'AddressForm',

  props: {
    value: {
      type: Object,
      default: () =>({}),
    },
  },

  components: {
    CountrySelect,
  },

  computed: {
    address: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  },
};
</script>
